import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { BeepService } from './beep.service';
import Quagga from '@ericblade/quagga2';
import { Article } from './article';
import { UpdateService } from './update.service';
import { environment } from '../environments/environment';
import { getMainBarcodeScanningCamera } from './camera-access';
import { HttpParams } from '@angular/common/http';

//import products from '../assets/products.json';
//import prices from '../assets/prices.json';

//import products from '../assets/products-08042022.json';
//import prices from '../assets/prices-08042022.json';

//import products from '../assets/products-11172022.json';
//import prices from '../assets/prices-11172022.json';

//import products from '../assets/products-11252022.json';
//import products from '../assets/products-12072022.json';
import products from '../assets/products-01162023.json';

import { Product } from './product';
import { Price } from './price';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit 
{
  started: boolean | undefined;
  errorMessage: string | undefined;
  acceptAnyCode = true;
  items: [Article, number][] = [];
  totalPrice: number = 0;
  productsScanned: Product[] = [];

  private lastScannedCode: string | undefined;
  private lastScannedCodeDate: number  | undefined;

  private _products: Product[] = [];
  private _prices: Price[] = [];
  private _seqNumber: string | null;
  private _securityKey: string | null;
  private _callbackUrl:string | null;
  private _enableScan: boolean = true;

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private beepService: BeepService,
              private updateService: UpdateService) 
  {
    //<! initializes products and prices
    //this.productsScanned = this._products = products as Product[];
    this._products = products as Product[];
    //this._prices = prices as Price[]; 

    //<! gets query parameters from url through URLSearchParams object    
    const params = new URLSearchParams(window.location.search);
    this._seqNumber = params.get('seq') || params.get('Seq');
    this._securityKey = params.get('key') || params.get('Key');
    this._callbackUrl = params.get('url') || params.get('URL');
  }

  ngAfterViewInit(): void 
  {    
    if (this._seqNumber == null || this._securityKey == null || this._callbackUrl == null)  
    {
      this.errorMessage = 'Seq, Key, or URL cannot be null or empty';
      return;
    }

    if (!navigator.mediaDevices || !(typeof navigator.mediaDevices.getUserMedia === 'function')) 
    {
      this.errorMessage = 'getUserMedia is not supported';
      return;
    }

    this.initializeScanner();    
  }

  private initializeScanner(): Promise<void> 
  {
    if (!navigator.mediaDevices || !(typeof navigator.mediaDevices.getUserMedia === 'function')) 
    {
      this.errorMessage = 'getUserMedia is not supported. Please use Chrome on Android or Safari on iOS';
      this.started = false;
      return Promise.reject(this.errorMessage);
    }

    // enumerate devices and do some heuristics to find a suitable first camera
    return Quagga.CameraAccess.enumerateVideoDevices()
      .then(mediaDeviceInfos => {
        const mainCamera = getMainBarcodeScanningCamera(mediaDeviceInfos);
        if (mainCamera) {
          console.log(`Using ${mainCamera.label} (${mainCamera.deviceId}) as initial camera`);
          return this.initializeScannerWithDevice(mainCamera.deviceId);
        } else {
          console.error(`Unable to determine suitable camera, will fall back to default handling`);
          return this.initializeScannerWithDevice(undefined);
        }
      })
      .catch(error => {
        this.errorMessage = `Failed to enumerate devices: ${error}`;
        this.started = false;
      });
  }

  private initializeScannerWithDevice(preferredDeviceId: string | undefined): Promise<void> 
  {
    console.log(`Initializing Quagga scanner...`);

    const constraints: MediaTrackConstraints = {};
    if (preferredDeviceId) 
    {
      // if we have a specific device, we select that
      constraints.deviceId = preferredDeviceId;
    } else 
    {
      // otherwise we tell the browser we want a camera facing backwards (note that browser does not always care about this)
      constraints.facingMode = 'environment';
    }

    return Quagga.init({
        inputStream: {
          type: 'LiveStream',
          constraints,
          area: { // defines rectangle of the detection/localization area
            top: '25%',    // top offset
            right: '10%',  // right offset
            left: '10%',   // left offset
            bottom: '25%'  // bottom offset
          },
          target: document.querySelector('#scanner-container') ?? undefined
        },
        decoder: {
          readers: ['ean_reader', 'code_128_reader', 'upc_reader'],
          multiple: false
        },
        // See: https://github.com/ericblade/quagga2/blob/master/README.md#locate
        locate: false
      },
      (err) => {
        if (err) {
          console.error(`Quagga initialization failed: ${err}`);
          this.errorMessage = `Initialization error: ${err}`;
          this.started = false;
        } else {
          console.log(`Quagga initialization succeeded`);
          Quagga.start();
          this.started = true;
          this.changeDetectorRef.detectChanges();
          Quagga.onDetected((res) => {
            if (res.codeResult.code) 
            {              
              if (!this._enableScan) {
                return;
              }

              this.onBarcodeScanned(res.codeResult.code);
            }
          });
        }
      });
  }

  onBarcodeScanned(code: string) 
  {    
    // ignore duplicates for an interval of 1.5 seconds
    const now = new Date().getTime();

    if (code === this.lastScannedCode && ((this.lastScannedCodeDate !== undefined) && (now < this.lastScannedCodeDate + 1500))) 
    {
      return;
    }

    let product = this._products.find(o => o.ModelNo === code || o.ModelNo === code.substring(1, code.length));

    if (!product) 
    {
      this.errorMessage = `${code} is not valid. Please scan a valid barcode`;
      return;
    }

    if (this.productsScanned.findIndex(o => o == product) > -1) 
    {
      this.errorMessage = `${code} is already scanned. Please scan a different barcode`;
      return;
    }

    let price = this._prices.find(o => o.ItemSeq === product?.Seq);

    product.UnitPrice = (price) ? price.Price1 : 0.0000;

    this.errorMessage = undefined;
    this.productsScanned.push(product);

    this.reditectToCallbackUrl();
  }
  
  handleCloseClickEvent(e: MouseEvent): void 
  {    
    if (window.confirm(`Are you sure you want to close this page?`)) 
    {
      let params: HttpParams = new HttpParams()
      .set("seq", this._seqNumber || "")
      .set("key", this._securityKey || "")
      .set("items", "0");

      window.location.href = `${decodeURIComponent(this._callbackUrl as string)}?${params.toString()}`;
    }
  }

  reditectToCallbackUrl(): void
  {
    this._enableScan = false;

    let params: HttpParams = new HttpParams()
      .set("seq", this._seqNumber || "")
      .set("key", this._securityKey || "")
      .set("items", this.productsScanned.map(o => o.Seq).join(','));

    window.location.href = `${decodeURIComponent(this._callbackUrl as string)}?${params.toString()}`;    
    //window.alert(`${decodeURIComponent(this._callbackUrl as string)}?${params.toString()}`);
  }
}
